(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.AiDrapesService = f()}})(function(){var define,module,exports;return (function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.solutionExists = exports.oldObjects = exports.loadFromFile = exports.iterateDrapesSolutionForGroup = exports.generateDrapesSolutionForScene = exports.generateDrapesSolution = void 0;
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
/**
 * Module de génération des rideaux et tringles par IA.
 * @module aiDrapesModule
 * 
 */

var EPSILLON = 10; // millimeter precision
var EPSILLON_WINDOWS = 500; // maximum distance between two windows to be merged
var EPSILLON_RADIATORS = 500; // maximum distance to consider that a radiator is connected to the windows

var MARGIN_JOINERY = 150; // rod <-> joinery clearance
var MARGIN_DRAPES = 40; // end of rod <-> drape clearance
var MARGIN_HSP = 100; // rod <-> sealing clearance
var MARGIN_WALL = 170; //rod <-> wall clearance
var CONSTANT_WALL_CONNECTION = 200; // min distance to the adjacent wall
var DEPTH_T_RAIL = 100; // Rail rod min distance to supporting wall
var MIN_MARGIN = 50; // Minimum vertical clearance rod <-> joinery 
var MAX_D_SHORT = 150; //Maximum short overflow
var MAX_D_NORMAL = 300; //Maximum normal overflow
var MAX_D_BIG = 450; //Maximum big overflow
var REF_T_CLASSIC = "650d8699b66f88a80c878e44"; // AM reference to CLASSIC rod
var REF_T_RAIL = "650d942ecfe01f766a7350bd"; // AM reference to RAIL rod
var REF_T_DEEP = "650d868bb66f88a80c878e15"; // AM reference to DEEP rod
var REF_DRAPES_RAIL = "651a68d41aff6e758c0a93a5"; // AM reference to DRAPES of rail rods
var REF_DRAPES_CLASSIC = "651a68bd1aff6e758c0a934f"; // AM reference to DRAPES of none rail rods
var oldObjects = exports.oldObjects = [];
var roomCount = 0;
var table_results = [];
var T_CLASSIC = 0;
var T_DEEP = 1;
var T_RAIL = 2;
var H_CEILING = 3;
var H_JOINERY = 4;
var D_ZERO = 5;
var D_SHORT = 6;
var D_NORMAL = 7;
var D_BIG = 8;
var M_JOINERY = 9;
var M_HSP = 10;
var M_DEPTH = 11;
var ID_TO_AM_OBJECT_MAP = [];

/**
 * reads a .rhinov file and returns the result as text.
 * @param l_filename
 * @returns {string}
 */
var readFile = function readFile(l_filename) {
  var rf = "";
  try {
    rf = fs.readFileSync(l_filename, "utf8");
    //console.log(data);
  } catch (err) {
    //console.error(err);
  }
  return rf;
};

/**
 * Apples HSP constraints 
 * @param l_group
 * @returns {number[]}
 */
var maskHsp = function maskHsp(l_group) {
  var resp = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  if (l_group.height > 2900) {
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  } else if (l_group.hsp > 3000 || l_group.h_clearance > 500) {
    resp[T_RAIL] = 0;
    resp[H_CEILING] = 0;
    return resp;
  } else if (l_group.h_clearance < MARGIN_JOINERY + MARGIN_HSP) {
    if (l_group.isBattant) {
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
    resp[M_JOINERY] = 0;
    resp[M_HSP] = 0;
    //return 0x111111111001;
    return resp;
  }
  return [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
};
/**
 * Applies radiator or Shutter cas constaints.
 * @param l_group
 * @returns {number[]}
 */
var maskRadorShutter = function maskRadorShutter(l_group) {
  var resp = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  if (l_group.hasRadorShutter) {
    resp[T_CLASSIC] = 0;
    //0x011111111111;
    return resp;
  }
  resp[M_DEPTH] = 0;
  resp[T_DEEP] = 0;
  // 0x110111111110;
  return resp;
};
/**
 * Applies Size constraints.
 * @param l_group
 * @returns {number[]}
 */
var maskSize = function maskSize(l_group) {
  var resp = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  if (l_group.width < 500 || l_group.height < 1600 || l_group.height > 2900) {
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  } else if (l_group.width < 700) {
    resp[D_ZERO] = 0; // l_group.l_clearance < MAX_D_SHORT || l_group.r_clearance < MAX_D_SHORT;
    return resp;
    //0x111110111111; 
  } else if (l_group.width < 3690) {
    resp[D_SHORT] = 0;
    resp[D_ZERO] = 0;
    resp[D_BIG] = 0;
    return resp;
    //0x111110010111; 
  } else if (l_group.width < 4000) {
    resp[D_NORMAL] = 0;
    resp[D_ZERO] = 0;
    resp[D_BIG] = 0;
    return resp;
    //0x111110100111; 
  } else if (l_group.width >= 4000) {
    resp[D_NORMAL] = 0;
    resp[D_BIG] = 0;
    return resp;
    //0x111110100111; 
  }

  return [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
};
/**
 * Adds a joinery to a group of joineries.
 * @param l_win
 * @param l_group
 * @returns {boolean}
 */
var isPartOfGroup = function isPartOfGroup(l_win, l_group) {
  if (l_group.length === 0) {
    l_group.windows.push(l_win);
    return true;
  }
  var rightSide = l_group.right;
  var leftSide = l_win.center - l_win.joinery.length / 2.0;
  var dist = Math.abs(leftSide - rightSide);
  if (dist < EPSILLON_WINDOWS) {
    l_group.windows.push(l_win);
    //l_group.width += (l_win.joinery.length + dist);
    l_group.right = l_win.center + l_win.joinery.length / 2.0;
    var joinH = l_win.joinery.transom ? l_win.joinery.transomHeight : l_win.joinery.height + l_win.joinery.floorHeight;
    if (joinH > l_group.height) l_group.height = joinH;
    l_group.width = Math.abs(l_group.left - l_group.right);
    l_group.h_clearance = l_group.hsp - l_group.height;
    l_group.l_clearance = Math.abs(l_group.wall_begin - l_group.left) - CONSTANT_WALL_CONNECTION;
    l_group.r_clearance = Math.abs(l_group.wall_end - l_group.right) - CONSTANT_WALL_CONNECTION;
    l_group.hasRadorShutter |= l_win.joinery.hasRollingShutter();
    return true;
  }
  return false;
};

/**
 * Creates or updates groups of joineries.
 * @param l_groups a red to a list of groups
 * @param l_windows a list of joineries to add to the group
 * @param l_wall the wall supporting our joineries
 * @param l_room a room to parent our objects
 */
var groupJoineries = function groupJoineries(l_groups, l_windows, l_wall, l_room) {
  var _iterator = _createForOfIteratorHelper(l_windows),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var win = _step.value;
      var added = false;
      var _iterator2 = _createForOfIteratorHelper(l_groups),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var group = _step2.value;
          added = isPartOfGroup(win, group);
          if (added) break;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      if (!added) {
        // create 
        var left = win.center - win.joinery.length / 2.0;
        var right = win.center + win.joinery.length / 2.0;
        var height = win.joinery.transom ? win.joinery.transomHeight : win.joinery.height + win.joinery.floorHeight;
        var toAdd = {
          wall: l_wall,
          windows: [win],
          width: win.joinery.length,
          height: height,
          left: left,
          right: right,
          hasRadorShutter: win.joinery.hasRollingShutter() || win.hasRad,
          h_clearance: l_wall.height - height,
          l_clearance: Math.abs(0 - (left + right) / 2.0) /*- CONSTANT_WALL_CONNECTION*/,
          r_clearance: Math.abs(l_wall.length - (left + right) / 2.0) /*- CONSTANT_WALL_CONNECTION*/,
          hsp: l_wall.height,
          // faire un truc pour le hsp en fonction sous-pente oui / non en fonction du haut de la menuiserie. 
          isBattant: win.joinery.openingMode === Savane.SceneConstants.OpeningMode.mode_default,
          wall_end: l_wall.length,
          wall_begin: 0,
          code: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          room: l_room
        };
        l_groups.push(toAdd);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
};

/**
 * Performs unary & operation on multiple boolean tables.
 * @param l_codes
 * @returns {number[]}
 */
var and = function and(l_codes) {
  var res = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  for (var i = 0; i < 12; i++) {
    var _iterator3 = _createForOfIteratorHelper(l_codes),
      _step3;
    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var code = _step3.value;
        res[i] = res[i] && code[i];
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  }
  return res;
};
/**
 * Applies all constrains to a group of joineries.
 * @param l_group
 */
var applyConstrains = function applyConstrains(l_group) {
  l_group.code = and([maskHsp(l_group), maskSize(l_group), maskRadorShutter(l_group)]);
};
/**
 * Is the radiator on the wall.
 * @param l_radiator
 * @param l_wall
 * @returns {boolean}
 */
var isOnWall = function isOnWall(l_radiator, l_wall) {
  var wallSegment = l_wall.segment;
  var position = l_radiator.position;
  position[2] = l_wall.begin[2];
  // project 
  var projection = wallSegment.orthogonalProjection(position);
  var vector = Savane.Math.glMatrix.vec3.create();
  Savane.Math.glMatrix.vec3.scale(projection, projection, -1);
  Savane.Math.glMatrix.vec3.add(vector, projection, position);
  var length = Savane.Math.glMatrix.vec3.length(vector);
  var bothWidth = (l_radiator.width + l_wall.thickness) / 2.0;
  return Math.abs(length - bothWidth) < EPSILLON;
};

/**
 * Is the radiator close to the window.
 * @param l_radiator
 * @param l_wall
 * @param l_window
 * @returns {boolean}
 */
var isCloseToWindow = function isCloseToWindow(l_radiator, l_wall, l_window) {
  var wallSegment = l_wall.segment;
  var positionR = l_radiator.position;
  var positionW = l_window.position;
  positionR[2] = l_wall.begin[2];
  positionW[2] = l_wall.begin[2];
  var projectionR = wallSegment.orthogonalProjection(positionR);
  var projectionW = wallSegment.orthogonalProjection(positionW);
  var vector = Savane.Math.glMatrix.vec3.create();
  Savane.Math.glMatrix.vec3.scale(projectionR, projectionR, -1);
  Savane.Math.glMatrix.vec3.add(vector, projectionW, projectionR);
  var length = Savane.Math.glMatrix.vec3.length(vector);
  var bothLength = (l_radiator.length + l_window.length) / 2.0;
  return length - bothLength < EPSILLON_RADIATORS;
};
/**
 * Iterates the scene to find the groups of joineries.
 * @param l_world the current world
 * @returns {Array} groups of windows
 */
var retriveJoineryGroupsFromWorld = function retriveJoineryGroupsFromWorld(l_world) {
  var groups = [];
  var _iterator4 = _createForOfIteratorHelper(l_world.children),
    _step4;
  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var scene = _step4.value;
      //floors
      var _iterator5 = _createForOfIteratorHelper(scene.children),
        _step5;
      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var floor = _step5.value;
          //rooms
          var _iterator6 = _createForOfIteratorHelper(floor.rooms),
            _step6;
          try {
            for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
              var room = _step6.value;
              roomCount++;
              retriveJoineryGroups(room, groups);
            }
          } catch (err) {
            _iterator6.e(err);
          } finally {
            _iterator6.f();
          }
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }
  for (var _i = 0, _groups = groups; _i < _groups.length; _i++) {
    var group = _groups[_i];
    applyConstrains(group);
  }
  return groups;
};

/**
 * Iterates through the walls in the room l_world to find all groups of joineries.
 * @param l_room
 * @param l_groups
 * @returns {Array}
 */
var retriveJoineryGroups = function retriveJoineryGroups(l_room, l_groups) {
  var walls = l_room.walls;
  var radiators = l_room.floor.technicalElements;
  radiators = radiators.filter(function (value) {
    return value.objectId === Savane.SceneConstants.TechnicalElementType.radiator && l_room.isInRoom(value.position);
  });
  var _iterator7 = _createForOfIteratorHelper(walls),
    _step7;
  try {
    for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
      var wall = _step7.value;
      var joineries = wall.joineries;
      var windows = [];
      var _iterator8 = _createForOfIteratorHelper(joineries),
        _step8;
      try {
        for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
          var joinery = _step8.value;
          if (joinery.joineryType === Savane.SceneConstants.JoineryType.fixedWindow || joinery.joineryType === Savane.SceneConstants.JoineryType.frenchWindow || joinery.joineryType === Savane.SceneConstants.JoineryType.pictureWindow || joinery.joineryType === Savane.SceneConstants.JoineryType.window) {
            if (!joinery.isInRoom(l_room)) continue;
            var localPos = joinery.transform.localPosition;
            var hasRad = false;
            var _iterator9 = _createForOfIteratorHelper(radiators),
              _step9;
            try {
              for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
                var rad = _step9.value;
                if (isOnWall(rad, wall) && isCloseToWindow(rad, wall, joinery)) {
                  hasRad = true;
                  break;
                }
              }
            } catch (err) {
              _iterator9.e(err);
            } finally {
              _iterator9.f();
            }
            windows.push({
              joinery: joinery,
              center: localPos[0],
              hasRad: hasRad
            });
          }
        }
      } catch (err) {
        _iterator8.e(err);
      } finally {
        _iterator8.f();
      }
      windows.sort(function (l_a, l_b) {
        return -l_b.center + l_a.center;
      });
      groupJoineries(l_groups, windows, wall, l_room);
    }
  } catch (err) {
    _iterator7.e(err);
  } finally {
    _iterator7.f();
  }
};
/**
 * Depth from rod type
 * @param l_type
 * @param l_hasRadorShutter
 * @returns {number|*}
 */
var chooseDepth = function chooseDepth(l_type, l_hasRadorShutter) {
  if (l_type === "T_RAIL") {
    return DEPTH_T_RAIL + (l_hasRadorShutter ? MARGIN_WALL : 0);
  }
  return 0;
};
/**
 * AM ref from drapes or rod type
 * @param l_type
 * @param l_isdrape
 * @returns {string}
 */
var getRef = function getRef(l_type, l_isdrape) {
  if (l_type === "T_RAIL") return l_isdrape ? REF_DRAPES_RAIL : REF_T_RAIL;else if (l_type === "T_DEEP") return l_isdrape ? REF_DRAPES_CLASSIC : REF_T_DEEP;
  return l_isdrape ? REF_DRAPES_CLASSIC : REF_T_CLASSIC;
};
/**
 * Rod width and overflows from group width and rod type.
 * @param l_group
 * @param l_type
 * @returns {Array}
 */
var chooseWidth = function chooseWidth(l_group, l_type) {
  var widths = [];
  if (l_group.code[D_SHORT]) {
    if (l_group.width < 700) {
      // 1 solution
      widths.push({
        left: MAX_D_BIG,
        right: MAX_D_SHORT,
        width: l_group.width,
        drapes: [{
          left: true,
          right: false,
          ref: getRef(l_type, true)
        }]
      });
    } else {
      widths.push({
        left: MAX_D_SHORT,
        right: MAX_D_SHORT,
        width: l_group.width,
        drapes: [{
          left: true,
          right: true,
          ref: getRef(l_type, true)
        }]
      });
    }
  }
  if (l_group.code[D_NORMAL]) {
    if (l_group.width < 1200) {
      widths.push({
        left: MAX_D_NORMAL,
        right: MAX_D_NORMAL,
        width: l_group.width,
        drapes: [{
          left: true,
          right: false,
          ref: getRef(l_type, true)
        }, {
          left: false,
          right: true,
          ref: getRef(l_type, true)
        }]
      });
    } else {
      widths.push({
        left: MAX_D_NORMAL,
        right: MAX_D_NORMAL,
        width: l_group.width,
        drapes: [{
          left: true,
          right: true,
          ref: getRef(l_type, true)
        }]
      });
    }
  }
  if (l_group.code[D_BIG]) {
    //D_BIG
    widths.push({
      left: MAX_D_SHORT,
      right: MAX_D_BIG,
      width: l_group.width,
      drapes: [{
        left: false,
        right: true,
        ref: getRef(l_type, true)
      }]
    });
  }
  return widths;
};
/**
 * rod position in height from rod type an hsp.
 * @param l_group
 * @param l_type
 * @returns {Array}
 */
var chooseHeight = function chooseHeight(l_group, l_type) {
  var code = l_group.code;
  var heights = [];
  if (l_type === "T_CLASSIC" || l_type === "T_DEEP") {
    if (code[H_CEILING] || code[H_JOINERY]) {
      // ajouter les marges
      var maxHeight = l_group.hsp - MARGIN_HSP;
      var minHeight = l_group.height + MARGIN_JOINERY;
      if (maxHeight > minHeight) {
        // cas normal => proposer les 2 
        heights.push({
          height: maxHeight,
          depth: chooseDepth(l_type, l_group.hasRadorShutter),
          width: chooseWidth(l_group, l_type)
        });
        heights.push({
          height: minHeight,
          depth: chooseDepth(l_type, l_group.hasRadorShutter),
          width: chooseWidth(l_group, l_type)
        });
      } else {
        if (!l_group.isBattant) {
          var diff = minHeight - maxHeight;
          if (diff < MIN_MARGIN)
            // use diff
            {
              minHeight = l_group.height + diff;
            } else {
            // 
            minHeight = l_group.height + MIN_MARGIN;
          }
          if (maxHeight > minHeight) {
            // cas normal => proposer les 2 
            heights.push({
              height: maxHeight,
              depth: chooseDepth(l_type, l_group.hasRadorShutter),
              width: chooseWidth(l_group, l_type)
            });
            heights.push({
              height: minHeight,
              depth: chooseDepth(l_type, l_group.hasRadorShutter),
              width: chooseWidth(l_group, l_type)
            });
          } else {
            diff = minHeight - maxHeight;
            if (diff < MIN_MARGIN)
              // use diff
              {
                maxHeight = l_group.height - diff;
              } else {
              // 
              maxHeight = l_group.height - MIN_MARGIN;
            }
            if (maxHeight > minHeight) {
              // cas normal => proposer les 2 
              heights.push({
                height: maxHeight,
                depth: chooseDepth(l_type, l_group.hasRadorShutter),
                width: chooseWidth(l_group, l_type)
              });
              heights.push({
                height: minHeight,
                depth: chooseDepth(l_type),
                width: chooseWidth(l_group, l_type)
              });
            } else
              // reduire � 0 la marge menuiserie
              {
                diff = minHeight - maxHeight;
                minHeight = l_group.height + diff;
                if (maxHeight > minHeight) {
                  // cas normal => proposer les 2 
                  heights.push({
                    height: maxHeight,
                    depth: chooseDepth(l_type, l_group.hasRadorShutter),
                    width: chooseWidth(l_group, l_type)
                  });
                  heights.push({
                    height: minHeight,
                    depth: chooseDepth(l_type, l_group.hasRadorShutter),
                    width: chooseWidth(l_group, l_type)
                  });
                }
              }
          }
        } else {
          var _diff = minHeight - maxHeight;
          if (_diff < MIN_MARGIN)
            // use diff
            {
              minHeight = l_group.height + _diff;
            } else {
            // 
            minHeight = l_group.height + MIN_MARGIN;
          }
          if (maxHeight > minHeight) {
            // cas normal => proposer les 2 
            heights.push({
              height: maxHeight,
              depth: chooseDepth(l_type, l_group.hasRadorShutter),
              width: chooseWidth(l_group, l_type)
            });
            heights.push({
              height: minHeight,
              depth: chooseDepth(l_type, l_group.hasRadorShutter),
              width: chooseWidth(l_group, l_type)
            });
          } else {
            _diff = minHeight - maxHeight;
            if (_diff < MIN_MARGIN)
              // use diff
              {
                maxHeight = l_group.height - _diff;
              } else {
              // 
              maxHeight = l_group.height - MIN_MARGIN;
            }
            if (maxHeight > minHeight) {
              // cas normal => proposer les 2 
              heights.push({
                height: maxHeight,
                depth: chooseDepth(l_type, l_group.hasRadorShutter),
                width: chooseWidth(l_group, l_type)
              });
              heights.push({
                height: minHeight,
                depth: chooseDepth(l_type, l_group.hasRadorShutter),
                width: chooseWidth(l_group, l_type)
              });
            }
          }
        }
      }
    }
  } else {
    // T_RAIL
    if (code[H_CEILING]) {
      heights.push({
        height: l_group.hsp,
        depth: chooseDepth(l_type, l_group.hasRadorShutter),
        width: chooseWidth(l_group, l_type)
      });
    }
  }
  return heights;
};
// rod from l_group 
var chooseRod = function chooseRod(l_group) {
  var sol = {
    tringles: []
  };
  var code = l_group.code;
  if (code[T_CLASSIC]) {
    var solTemplate = {
      type: "T_CLASSIC",
      height: chooseHeight(l_group, "T_CLASSIC"),
      wall: l_group.wall,
      win: l_group.windows,
      center: (l_group.left + l_group.right) / 2.0,
      l_clearance: l_group.l_clearance /*- CONSTANT_WALL_CONNECTION*/,
      r_clearance: l_group.r_clearance /*- CONSTANT_WALL_CONNECTION*/,
      ref: getRef("T_CLASSIC", false)
    };
    sol.tringles.push(solTemplate);
  }
  if (code[T_DEEP]) {
    var _solTemplate = {
      type: "T_DEEP",
      height: chooseHeight(l_group, "T_DEEP"),
      wall: l_group.wall,
      win: l_group.windows,
      center: (l_group.left + l_group.right) / 2.0,
      l_clearance: l_group.l_clearance /*- CONSTANT_WALL_CONNECTION*/,
      r_clearance: l_group.r_clearance /*- CONSTANT_WALL_CONNECTION*/,
      ref: getRef("T_DEEP", false)
    };
    sol.tringles.push(_solTemplate);
  }
  if (code[T_RAIL]) {
    var _solTemplate2 = {
      type: "T_RAIL",
      height: chooseHeight(l_group, "T_RAIL"),
      wall: l_group.wall,
      win: l_group.windows,
      center: (l_group.left + l_group.right) / 2.0,
      l_clearance: l_group.l_clearance /*- CONSTANT_WALL_CONNECTION*/,
      r_clearance: l_group.r_clearance /*- CONSTANT_WALL_CONNECTION*/,
      ref: getRef("T_RAIL", false)
    };
    sol.tringles.push(_solTemplate2);
  }
  return sol;
};

/**
 * Creates a solution tree from all groups of windows.
 * @param l_groups
 * @returns {Array}
 */
var buildSolution = function buildSolution(l_groups) {
  //console.log(l_groups);
  var solutionLists = [];
  var _iterator10 = _createForOfIteratorHelper(l_groups),
    _step10;
  try {
    for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
      var group = _step10.value;
      // choose rod
      var solutions = {
        group: {},
        solutions: []
      };
      solutions.group = group;
      solutions.solutions = chooseRod(group);
      solutionLists.push(solutions);
    }
  } catch (err) {
    _iterator10.e(err);
  } finally {
    _iterator10.f();
  }
  return solutionLists;
};
/**
 * Positions the rod and drapes
 * @param l_arrangementGroup An arrangementGroup to store our entities
 * @param l_entity can be a rod or a drape object created with createAssetEntity
 * @param l_room 
 * @param l_solution the current solution
 * @param l_array Stores all entities. Used to check if our solution is fully spawned
 * @param l_isdrape we are spawning a drape
 * @param l_isLeft the drape is in the left end of the rod
 * @param l_rodDepth depth of the rod
 * @param l_callback_idle_Deco function to add the arrangement group to designer view. See CocosPlanModule/src/cocos/scripts/state/actionState/IdleDecoration.js
 */
var callBackTreatEntityRod = function callBackTreatEntityRod(l_arrangementGroup, l_entity, l_room, l_solution, l_array, l_isdrape, l_isLeft, l_rodDepth, l_callback_idle_Deco) {
  // SET POSITION ON WALL 

  var position = Savane.Math.glMatrix.vec3.create(); // the final pos holder

  var finalWidth = l_solution.width + l_solution.leftSpace + l_solution.rightSpace; // add length + debord
  if (finalWidth > 4000) {
    finalWidth = 4000;
  }
  var diffDebord = (-l_solution.leftSpace + l_solution.rightSpace) / 2.0;
  var center = l_solution.center + diffDebord; /*+ CONSTANT_WALL_CONNECTION*/

  // add margins if applicable
  var marginLeft = center - finalWidth / 2.0 - CONSTANT_WALL_CONNECTION;
  var marginRight = l_solution.wall.length - CONSTANT_WALL_CONNECTION - center - finalWidth / 2.0;

  // console.log("we still have " +  marginLeft +"mm to the left");
  // console.log("we still have " +  marginRight +"mm to the right");
  if (marginLeft < 0) center -= marginLeft;
  if (marginRight < 0) center += marginRight;
  if (l_isdrape)
    // drapes positions at the end of the rod
    {
      if (l_isLeft)
        // left drape center it to the left
        {
          center -= (finalWidth - l_entity.length) / 2.0 - MARGIN_DRAPES;
        } else {
        center += (finalWidth - l_entity.length) / 2.0 - MARGIN_DRAPES;
      }
    }
  Savane.Math.glMatrix.vec3.scaleAndAdd(position, l_solution.wall.begin, l_solution.wall.wallDirection, center);
  l_entity.transform.localPosition = position;

  // MOVE FRONT
  var joinery = l_solution.win[0].joinery;
  var normalJoinery = Savane.Math.glMatrix.vec3.clone(l_solution.wall.normal);
  var thickness = (l_solution.wall.thickness + l_rodDepth) / 2.0 + l_solution.depth;
  if (l_isdrape) {
    thickness += l_rodDepth / 2.0;
    if (l_solution.tringle !== "T_RAIL") {
      thickness -= 30.0;
    }
  }
  if (!l_solution.win[0].joinery.orientation) thickness = -thickness;
  Savane.Math.glMatrix.vec3.scale(normalJoinery, normalJoinery, thickness);
  Savane.Math.glMatrix.mat4.translate(l_entity.transform.localMatrix, l_entity.transform.localMatrix, normalJoinery);
  var actualPos = l_entity.transform.localPosition;
  // if(l_room.isInRoom(actualPos))
  // {
  //     console.log("In the room " + l_solution.wall.normal + " " + l_solution.win[0].joinery.orientation);
  // }else{
  //     console.log("Not the room " + l_solution.wall.normal + " " + l_solution.win[0].joinery.orientation);
  // }

  //ROTATE
  var rotation = Savane.Transform.extractRotation(joinery.transform.globalMatrix);
  Savane.Math.glMatrix.mat4.multiply(l_entity.transform.localMatrix, l_entity.transform.localMatrix, rotation);
  var scale = l_entity.height - l_solution.height;

  // move up
  var heightCenter = l_solution.height - l_entity.height / 2.0 + (l_isdrape && l_solution.tringle !== "T_RAIL" ? MARGIN_DRAPES : 0) + (l_isdrape ? scale : 0);
  var translationHeight = Savane.Math.glMatrix.vec3.create();
  Savane.Math.glMatrix.vec3.cross(translationHeight, l_solution.wall.normal, l_solution.wall.wallDirection);
  Savane.Math.glMatrix.vec3.scale(translationHeight, translationHeight, heightCenter);
  translationHeight[2] += l_room.parent.height;
  Savane.Math.glMatrix.mat4.translate(l_entity.transform.localMatrix, l_entity.transform.localMatrix, translationHeight);
  if (l_solution.win[0].joinery.orientation) {
    Savane.Math.glMatrix.mat4.rotateZ(l_entity.transform.localMatrix, l_entity.transform.localMatrix, 3.14);
  }
  if (l_isdrape) {
    l_entity.height = l_solution.height;
  } else {
    l_entity.length = finalWidth;
  }

  // ADD TO GROUP

  l_arrangementGroup.addChild(l_entity);
  if (l_arrangementGroup.children.length === l_solution.nbToSpwan) {
    l_array.push({
      parent: l_room,
      entity: l_arrangementGroup,
      windows: l_solution.win
    });
    l_arrangementGroup.recenter();
    var toDel = [];
    for (var i = 0; i < oldObjects.length; i++) {
      var myObject = oldObjects[i];
      if (myObject.windows[0].joinery.id === l_solution.win[0].joinery.id) {
        toDel.push({
          id: i,
          entity: myObject.entity
        });
      }
    }
    // remove group first
    for (var _i2 = 0, _toDel = toDel; _i2 < _toDel.length; _i2++) {
      var del = _toDel[_i2];
      oldObjects.splice(del.id, 1);
    }

    // add new groups
    exports.oldObjects = oldObjects = oldObjects.concat(l_array);

    //callback
    l_callback_idle_Deco(l_array, toDel);
  }
};
/**
 * Spawns entities for a solution.
 * @param l_results result of the AM request for our entities ref.
 * @param l_solutions all solutions as a table
 * @param l_room
 * @param l_callback_idle_Deco function to add the arrangement group to designer view. See CocosPlanModule/src/cocos/scripts/state/actionState/IdleDecoration.js
 * @param l_index current solution index
 */
var callbackSpawning = function callbackSpawning(l_results, l_solutions, l_room, l_callback_idle_Deco, l_index) {
  var resourcesAm = l_results.data.resources;
  var returnedArray = [];
  for (var i = 0; i < resourcesAm.length; i++) {
    var res = resourcesAm[i];
    ID_TO_AM_OBJECT_MAP.push({
      id: resourcesAm[i]._id,
      value: resourcesAm[i]
    });
  }
  // if(l_index >= l_solutions.length)
  // {
  //     currentIndex = 0;
  // }

  var currentSolution = l_solutions[l_index];
  // create entity for rod
  var drapeValue = ID_TO_AM_OBJECT_MAP.filter(function (a) {
    return a.id === currentSolution.drapeRef;
  });
  var rodValue = ID_TO_AM_OBJECT_MAP.filter(function (a) {
    return a.id === currentSolution.rodRef;
  });
  var rodDepth = rodValue[0].value.dimensions.length;
  var arrangementGroup = Savane.EntityFactory.createEmptyArrangementGroup();
  for (var valSol in currentSolution) {
    if (valSol === "rightDrape" && currentSolution.rightDrape) {
      //create drape
      AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, drapeValue[0].value, false, function (res) {
        callBackTreatEntityRod(arrangementGroup, res, l_room, currentSolution, returnedArray, true, false, rodDepth, l_callback_idle_Deco);
      });
    } else if (valSol === "leftDrape" && currentSolution.leftDrape) {
      AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, drapeValue[0].value, false, function (res) {
        callBackTreatEntityRod(arrangementGroup, res, l_room, currentSolution, returnedArray, true, true, rodDepth, l_callback_idle_Deco);
      });

      //create drape
    } else if (valSol === "tringle") {
      AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, rodValue[0].value, false, function (res) {
        callBackTreatEntityRod(arrangementGroup, res, l_room, currentSolution, returnedArray, false, false, rodDepth, l_callback_idle_Deco);
      });
    }
  }
};
/**
 * gets drapes and rod refs from the AM
 * @param l_solutions all solutions as table.
 * @param l_room
 * @param l_callback_idle_Deco function to add the arrangement group to designer view. See CocosPlanModule/src/cocos/scripts/state/actionState/IdleDecoration.js
 * @param l_index current solution index
 */
var retriveAssetsForSpwaning = function retriveAssetsForSpwaning(l_solutions, l_room, l_callback_idle_Deco, l_index) {
  AssetManagerServices.getAssets(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, "&q._id.in=" + REF_T_CLASSIC + "," + REF_T_RAIL + "," + REF_DRAPES_CLASSIC + "," + REF_DRAPES_RAIL + "," + REF_T_DEEP, function (l_results) {
    if (l_results === undefined) {
      l_callback_idle_Deco([], []);
      return;
    }
    callbackSpawning(l_results, l_solutions, l_room, l_callback_idle_Deco, l_index);
  });
};
/**
 * converts our solution tree to a table
 * @param l_tree
 * @returns {Array}
 */
var treeToTable = function treeToTable(l_tree) {
  var allSolutions = [];
  //for (const sol of l_tree) { 
  var _iterator11 = _createForOfIteratorHelper(l_tree.tringles),
    _step11;
  try {
    for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
      var tringle = _step11.value;
      var _iterator12 = _createForOfIteratorHelper(tringle.height),
        _step12;
      try {
        for (_iterator12.s(); !(_step12 = _iterator12.n()).done;) {
          var height = _step12.value;
          var _iterator13 = _createForOfIteratorHelper(height.width),
            _step13;
          try {
            for (_iterator13.s(); !(_step13 = _iterator13.n()).done;) {
              var width = _step13.value;
              var _iterator14 = _createForOfIteratorHelper(width.drapes),
                _step14;
              try {
                for (_iterator14.s(); !(_step14 = _iterator14.n()).done;) {
                  var drape = _step14.value;
                  var allSol = {
                    nbToSpwan: 1,
                    tringle: tringle.type,
                    height: height.height,
                    depth: height.depth,
                    leftSpace: width.left,
                    rightSpace: width.right,
                    leftDrape: drape.left,
                    rightDrape: drape.right,
                    drapeRef: drape.ref,
                    width: width.width,
                    wall: tringle.wall,
                    win: tringle.win,
                    center: tringle.center,
                    rodRef: tringle.ref,
                    l_clearance: tringle.l_clearance /*- CONSTANT_WALL_CONNECTION*/,
                    r_clearance: tringle.r_clearance /*- CONSTANT_WALL_CONNECTION*/
                  };

                  if (allSol.rightDrape) allSol.nbToSpwan++;
                  if (allSol.leftDrape) allSol.nbToSpwan++;
                  allSolutions.push(allSol);
                }
              } catch (err) {
                _iterator14.e(err);
              } finally {
                _iterator14.f();
              }
            }
          } catch (err) {
            _iterator13.e(err);
          } finally {
            _iterator13.f();
          }
        }
      } catch (err) {
        _iterator12.e(err);
      } finally {
        _iterator12.f();
      }
    }
    //}
    //.log(allSolutions);
  } catch (err) {
    _iterator11.e(err);
  } finally {
    _iterator11.f();
  }
  return allSolutions;
};
/**
 * Iterates through the the different solutions
 * @param l_window the selected window
 * @param l_increment -1 if we press b + left 1 otherwise
 * @param l_callback_idle_Deco function to add the arrangement group to designer view. See CocosPlanModule/src/cocos/scripts/state/actionState/IdleDecoration.js
 */
var iterateDrapesSolutionForGroup = exports.iterateDrapesSolutionForGroup = function iterateDrapesSolutionForGroup(l_window, l_increment, l_callback_idle_Deco) {
  // find the window in all solutions
  for (var _i3 = 0, _table_results = table_results; _i3 < _table_results.length; _i3++) {
    var res = _table_results[_i3];
    var windows = res.group.windows;
    var _iterator15 = _createForOfIteratorHelper(windows),
      _step15;
    try {
      for (_iterator15.s(); !(_step15 = _iterator15.n()).done;) {
        var win = _step15.value;
        if (win.joinery.id === l_window.id) {
          res.currentIndex = (res.currentIndex + l_increment) % res.solutions.length;
          retriveAssetsForSpwaning(res.solutions, res.group.room, l_callback_idle_Deco, res.currentIndex);
        }
      }
    } catch (err) {
      _iterator15.e(err);
    } finally {
      _iterator15.f();
    }
  }
};
/**
 * 
 * @param l_world the current scene
 * @param l_callback_idle_Deco function to add the arrangement group to designer view. See CocosPlanModule/src/cocos/scripts/state/actionState/IdleDecoration.js
 * @returns {[group:{},solutions:[]]}
 */
var generateDrapesSolutionForScene = exports.generateDrapesSolutionForScene = function generateDrapesSolutionForScene(l_world, l_callback_idle_Deco) {
  var groups = retriveJoineryGroupsFromWorld(l_world);
  var solutions = buildSolution(groups);
  var _iterator16 = _createForOfIteratorHelper(solutions),
    _step16;
  try {
    for (_iterator16.s(); !(_step16 = _iterator16.n()).done;) {
      var sol = _step16.value;
      var table = treeToTable(sol.solutions);
      table_results.push({
        group: sol.group,
        solutions: table,
        currentIndex: 0
      });
    }
  } catch (err) {
    _iterator16.e(err);
  } finally {
    _iterator16.f();
  }
  if (table_results.length !== 0) {
    for (var _i4 = 0, _table_results2 = table_results; _i4 < _table_results2.length; _i4++) {
      var uniqueSolution = _table_results2[_i4];
      retriveAssetsForSpwaning(uniqueSolution.solutions, uniqueSolution.group.room, l_callback_idle_Deco, uniqueSolution.currentIndex);
    }
  }
};

/**
 * Entry point called from idleDecoration
 * @param l_room
 * @param l_callback_idle_Deco function to add the arrangement group to designer view. See CocosPlanModule/src/cocos/scripts/state/actionState/IdleDecoration.js
 */
var generateDrapesSolution = exports.generateDrapesSolution = function generateDrapesSolution(l_room, l_callback_idle_Deco) {
  var tree = buildSolution(retriveJoineryGroups(l_room));
  var result = treeToTable(tree);
  //console.log(result);
  if (result.length !== 0) {
    retriveAssetsForSpwaning(result, l_room, l_callback_idle_Deco);
  }
};
/**
 * test function if we want to run standAlone tests.
 * @deprecated 
 * @param l_fname
 */
var loadFromFile = exports.loadFromFile = function loadFromFile(l_fname) {
  var rhinovformat = JSON.parse(readFile(l_fname));
  var world = Savane.JSONImporter.importWorld(rhinovformat);
  var result = buildSolution(retriveJoineryGroups(world));
  retriveAssetsForSpwaning(result);
};
/**
 * Check if a solution exists. 
 * @param l_world
 * @returns {boolean} false if the number of rooms has changed or the solution was never created.
 */
var solutionExists = exports.solutionExists = function solutionExists(l_world) {
  if (table_results.length === 0) return false;
  var nbRooms = 0;
  var _iterator17 = _createForOfIteratorHelper(l_world.children),
    _step17;
  try {
    for (_iterator17.s(); !(_step17 = _iterator17.n()).done;) {
      var scene = _step17.value;
      //floors
      var _iterator18 = _createForOfIteratorHelper(scene.children),
        _step18;
      try {
        for (_iterator18.s(); !(_step18 = _iterator18.n()).done;) {
          var floor = _step18.value;
          //rooms
          var _iterator19 = _createForOfIteratorHelper(floor.rooms),
            _step19;
          try {
            for (_iterator19.s(); !(_step19 = _iterator19.n()).done;) {
              var room = _step19.value;
              nbRooms++;
            }
          } catch (err) {
            _iterator19.e(err);
          } finally {
            _iterator19.f();
          }
        }
      } catch (err) {
        _iterator18.e(err);
      } finally {
        _iterator18.f();
      }
    }
  } catch (err) {
    _iterator17.e(err);
  } finally {
    _iterator17.f();
  }
  return nbRooms === roomCount;
};

},{}]},{},[1])(1)
});
